<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card class="p-2 pt-4">
            <template v-slot:headerTitle>
              <h4 class="card-title">
                <i class="fa fa-calendar-check-o" aria-hidden="true" style="font-size: 20px; margin-right: 10px; color: var(--iq-primary);"></i>
                {{cvCardTitle}} [{{analytics.event_name}}]
              </h4>
              <span>Starts On: {{analytics.event_start_ts | dateFormatDDMMYYYYHHMMA}}</span><br>
              <span>Duration: {{(analytics.event_end_ts - analytics.event_start_ts) | durationToDHM}}</span><br>
            </template>
            <template v-slot:body>
              <div class="row w-100 mb-3 multiple_boxes_row">
                <div class="ml-2" v-for="(value, key, index) in eventAnalyticsCountObj" :key="key+value">
                  <div class="boxs" :style="'background:'+getBgFor(index)">
                    <iq-card class="btn mb-1 multiCardboxs" >
                      <span  class="textintworow text-white">{{key}} </span><br/>
                      <span class="badge badge-light">{{analytics[value]}}</span>
                    </iq-card>
                  </div>
                </div>
              </div>
              <b-row class="mb-3">
                <b-col md="4">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    :placeholder="cvSearchText"
                  ></b-form-input>
                </b-col>
                <b-col md="8" v-if="meetingReportObjList && meetingReportObjList.length">
                  <VueJsonToCsv :json-data="meetingReportObjList" class="pull-right" csv-title="userDocument" >
                    <b-btn variant="primary"> Download
                    </b-btn>
                  </VueJsonToCsv>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" v-if="meetingReportObjList && meetingReportObjList.length > 0">
                  <b-table
                    hover
                    responsive
                    :items="meetingReportObjList"
                    :fields="columns"
                    :no-border-collapse="true"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :current-page="currentPage"
                    :per-page="perPage"
                  >
                    <template v-slot:cell(sno)="data">
                      {{data.index + 1}}
                    </template>

                    <template v-slot:cell(user_name)="data">
                      {{data.item.user_name}}
                      <router-link :to="'/user/'+ data.item.user_id">
                        <i class="fa-solid fa-eye"></i>
                      </router-link>
                    </template>

                    <template v-slot:cell(org_name)="data">
                    <span v-if="data.item.org_name">
                      {{`${data.item.org_name ? data.item.org_name + ", " : " - "} ${data.item.org_name && data.item.org_city ? data.item.org_city + ", " : ""} ${data.item.org_name && data.item.org_state ? data.item.org_state + ", " : ""} ${data.item.org_name && data.item.org_country ? data.item.org_country + ", " : ""}`}}
                    </span>
                    <span v-else>
                      {{data.item.cls_organisation_name}}
                    </span>
                  </template>

                    <template v-slot:cell(user_join_time)="data">
                      {{data.item.user_join_time | dateFormatDDMMYYYYHHMMA}}
                    </template>

                    <template v-slot:cell(duration)="data">
                      {{data.item.duration | durationToDHM}}
                    </template>

                    <template v-slot:cell(class_name)="data">
                      {{data.item.class_name ? data.item.class_name : data.item.grade | addSuffixToClass}}
                    </template>
                  </b-table>
                </b-col>
                <b-col md="12" v-else>
                  No data found
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="7" md="8" class="my-1"></b-col>
                <b-col sm="5" md="4" class="my-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                    class="my-0"
                  ></b-pagination>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg"></div>
      </b-toast>
    </b-container>
</template>
<style lang="scss" scoped>
.multiCardboxs{
  background-color: transparent !important;
  width: 99% !important;
  display: block;
  height: 85px;
}
</style>

<script>
import { events } from "../../../FackApi/api/events.js"
import { socialvue } from "../../../config/pluginInit.js"
import { evantAnlytics } from "../../../FackApi/api/analytics/eventAnalytic.js"
import ColorCodes from "../../../Utils/colorcodes.js"

export default {
  name: "EventParticipantReport",
  components: {
  },
  data () {
    return {
      cvCardTitle: "Event Booth Checkin Report",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event Booth Checkin Report",
      sortBy: "name",
      sortDesc: false,
      columns: [],
      meetingReportObjList: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      eventId: null,
      analytics: {},
      eventAnalyticsCountObj: { // Key value pair where key is label and value will use to get data from api
        "Checkins": "event_total_checkin"
      },
      cvModulePrefix: "event_rsvp"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    switch (this.userData.user_role) {
      case "USERROLE11111": // Admin
      case "USERROLE11118": // Gide Counselor
        this.columns = [
          { label: "# ", key: "sno", class: "text-left", sortable: true },
          { label: "Name", key: "user_name", class: "text-left", sortable: true },
          { label: "Email", key: "user_email", class: "text-left", sortable: true },
          { label: "Contact", key: "user_mobile", class: "text-left", sortable: true },
          { label: "Parent Email", key: "parent_email", class: "text-left", sortable: true },
          { label: "Parent Mobile", key: "parent_mobile", class: "text-left", sortable: true },
          { label: "Passing Year", key: "passing_year", class: "text-left", sortable: true },
          { label: "Prefered country", key: "preferred_country", class: "text-left", sortable: true },
          { label: "Board", key: "board", class: "text-left", sortable: true },
          { label: "School", key: "org_name", class: "text-left", sortable: true },
          { label: "Class/Grade", key: "class_name", class: "text-left", sortable: true },
          { label: "Organisation", key: "org_name", class: "text-left", sortable: true }
        ]
        break
      case "USERROLE11115": // Univ Admin
      case "USERROLE11116": // Organisation
        this.columns = [
          { label: "# ", key: "sno", class: "text-left", sortable: true },
          { label: "Name", key: "user_name", class: "text-left", sortable: true },
          { label: "Email", key: "user_email", class: "text-left", sortable: true },
          { label: "Contact", key: "user_mobile", class: "text-left", sortable: true },
          { label: "Parent Email", key: "parent_email", class: "text-left", sortable: true },
          { label: "Parent Mobile", key: "parent_mobile", class: "text-left", sortable: true },
          { label: "Passing Year", key: "passing_year", class: "text-left", sortable: true },
          { label: "Prefered country", key: "preferred_country", class: "text-left", sortable: true },
          { label: "Board", key: "board", class: "text-left", sortable: true },
          { label: "School", key: "org_name", class: "text-left", sortable: true },
          { label: "Class/Grade", key: "class_name", class: "text-left", sortable: true }
        ]
        break
      default:
        break
    }

    this.eventId = this.$route.params.eventId
    this.eventMeetingId = this.$route.query.meeting_id
    socialvue.index()

    this.checkinmeetingReportList()
    this.EventAnalyticsBoothCheckinCount()
  },
  methods: {
    /**
       * getBgFor
       */
    getBgFor (index) {
      return ColorCodes.getBgColor(index)
    },
    /**
       * checkinmeetingReportList
       */
    async checkinmeetingReportList () {
      try {
        let eventObj = {
        }

        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "ORG"
        }

        let eventRsvpListResp = await events.eventAnalyticsBoothCheckinReport(this, this.eventId, eventObj)
        if (eventRsvpListResp.resp_status) {
          this.meetingReportObjList = eventRsvpListResp.resp_data.data
          this.totalRows = eventRsvpListResp.resp_data.count
        }
        else {
          this.toastMsg = eventRsvpListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at checkinmeetingReportList() and Exception:", err.message)
      }
    },
    /**
     * EventAnalyticsBoothCheckinCount
     */
    async EventAnalyticsBoothCheckinCount () {
      try {
        let eventObj = {
          event_id: this.eventId
        }

        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "ORG"
        }

        let eventAnalyticResp = await evantAnlytics.EventAnalyticsBoothCheckinCount(this, eventObj)
        if (eventAnalyticResp.resp_status) {
          this.analytics = eventAnalyticResp.resp_data.data
        }
        else {
          this.toastMsg = eventAnalyticResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at checkinmeetingReportList() and Exception:", err.message)
      }
    }
  }
}
</script>
